<template>
  <div v-loading="loading" element-loading-text="拼命加载中">
    <div v-if="!notask">
      <el-alert class="my-3" title="数据采集时间: " type="info" :closable="false" show-icon>{{updatedAt}}</el-alert>
      <section class="bg-white px-3 pt-3">
        <nav class="d-flex justify-content-between mb-3">
          <span class="font-weight-bold text-muted">任务统计</span>
          <el-select v-model="quesTitle" placeholder="请选择问题" size="small">
            <el-option
              v-for="name in choises"
              :key="name"
              :label="name"
              :value="name">
            </el-option>
          </el-select>
        </nav>
        <ve-pie :data="finishData"></ve-pie>
      </section>
      <section class="bg-white p-3 mt-3">
        <p class="font-weight-bold text-muted mb-3" v-show="currData.length > 0">任务详情</p>
        <div class="filter-container mb-2" v-show="currData.length > 0">
          <el-input v-model="search" size="medium" placeholder="输入关键字搜索" style="width:20%">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <el-button type="primary" size="medium" icon="el-icon-download" @click="exportXls()">导出数据</el-button>
        </div>
        <el-table :data="currData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))" border v-if="currData.length > 0">
          <el-table-column :label="curChoise" prop="name"></el-table-column>
          <el-table-column label="数量" prop="value"></el-table-column>
        </el-table>
      </section>
      <!-- <el-pagination
        class="text-center py-4 mb-3"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="thisData.length">
      </el-pagination> -->
    </div>
    <div class="container" v-else>
      <div class="text-center">
        <img src="@/assets/img/table1.png" alt="table" style="max-width: 400px;width:100%">
        <p class="h4 text-secondary">{{tips}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { sheet2blob, openDownloadDialog } from '@/lib/print/exportExcel'
import XLSX from 'xlsx'
import VePie from 'v-charts/lib/pie.common'
import { quesDetails } from '@/api/report-task'

let vm
// let taskID = document.location.pathname.split('/')[3]
// taskID = '1001'

export default {
  name: 'table-tasks',
  components: {
    VePie
  },
  data () {
    return {
      num1001: 0,
      tips: '暂无数据',
      currentPage1: 1,
      quesTitle: '',
      thisData: '',
      currData: [],
      curChoise: '',
      finishData: {
        columns: ['name', 'value'],
        rows: [
          // { 'name': '有效', 'value': 0 },
          // { 'name': '无效', 'value': 0 }
        ]
      },
      choises: [],
      updatedAt: null,
      loading: true,
      notask: true,
      search: ''
    }
  },
  created () {
    vm = this
    vm.getDatas()
  },
  computed: {
    taskID () {
      return this.$route.query.id
    }
  },
  watch: {
    quesTitle (curVal, oldVal) {
      vm.selectQues(curVal)
    },
    $route (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.getDatas()
      }
    }
  },
  methods: {
    exportXls () {
      const sheet = XLSX.utils.json_to_sheet(this.currData) // 将一个table对象转换成一个sheet对象
      sheet.A1.v = this.curChoise
      sheet.B1.v = '数量'
      openDownloadDialog(sheet2blob(sheet), `${this.taskID}-${this.curChoise}.xlsx`)
    },
    handleCurrentChange (val) {
      vm.currentPage1 = val
      vm.currData = vm.thisData.slice((val - 1) * 10, val * 10)
      console.log(`当前页: ${val}`)
    },
    testHeader (row, index) {
      return row.items[index].value || '/'
    },
    getDatas () {
      // console.log(this.taskID)
      this.loading = true
      quesDetails(this.taskID).then(
        res => {
          const { code, data } = res
          if (code === 0) {
            const report = data.reports || []
            vm.getChoise(report)
            vm.datas = report
            vm.quesTitle = report[0].name
            vm.selectQues(report[0].name)
            const time = new Date(data.updatedAt * 1000)
            vm.updatedAt = time.toLocaleString()
            vm.notask = false
            vm.loading = false
          } else if (code === 1001) {
            if (vm.num1001 < 2) {
              vm.num1001++
              setTimeout(() => {
                vm.getDatas()
              }, 20000) // 20s 后重试
            } else {
              vm.notask = true
              vm.tips = '数据获取中，请稍后尝试'
              vm.num1001 = 0
              vm.loading = false
            }
            // 超时，重试 2 次
          } else {
            vm.notask = true
            vm.tips = '暂无数据'
            console.log(res.message)
            vm.loading = false
          }
        }
      )
    },
    getChoise (datas) {
      vm.choises = []
      datas.forEach(val => {
        vm.choises.push(val.name)
      })
    },
    selectQues (name) {
      vm.curChoise = name
      for (const i in vm.datas) {
        const val = vm.datas[i]
        if (name === val.name) {
          if (val.items === undefined) {
            return
          }
          const arr = val.items.sort((a, b) => {
            return b.value - a.value
          })
          vm.finishData.rows = arr.slice(0, 10)
          vm.currData = val.items
          return
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
